import * as React from "react"
import { Box, Container, AppBar, Toolbar, Typography, DialogContent } from '@mui/material'
import ContactForm from '../components/ContactForm'

export const Head = () => (
  <>
    <title>HiMON® get quote – HIGHVOLT</title>
    <meta name="viewport" content="initial-scale=1, width=device-width" />
    <meta name="robots" content="noindex,nofollow" />
  </>
)


const CalculationPage = () => {

  return (
    <Box className='hv-style'>
      <Container maxWidth="lg" sx={{ padding: 0 }}>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Typography variant='h4' component='div' py={2} sx={{flexGrow: 1}}>Get your quote!</Typography>
          </Toolbar>
        </AppBar>
        <DialogContent sx={{display: 'flex', flexDirection: 'column'}}>
          <ContactForm />
        </DialogContent>
      </Container>
    </Box>
  )
}

export default CalculationPage
